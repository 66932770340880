export interface AppState {
    accessIsForbidden: boolean;
    allowedGroupActions: Array<AllowedAction>;
    allowedUserActions: Array<AllowedAction>;
    groups: Array<UserGroup>;
    groupsLoadMoreLink: string | null;
    roles: Array<UserGroupRole>;
    routeInfo: unknown;
    nextGroup: { group: UserGroup; redirect: boolean } | null;
    selectedGroup: UserGroup | undefined | null;
    selectedUser: User | null;
    serverErrorCode: any;
    sessionWarningAcknowledged: boolean;
    users: Array<User>;
    usersLoadMoreLink: string | null;
    isLoading: boolean;
    loadingError: any;
    tags: Array<Tag>;
    showCreateGroupDialog?: boolean;
    lastCreatedUserId: string | undefined;
}

export interface User {
    id: string;
    account_id: string;
    first_name: string;
    last_name: string;
    email: string | null;
    additional_authentication_factors?: string[];
    phone_number: string | null;
    group_ids: Array<string> | undefined;
    preferred_login: PreferredLogin;
    preferred_language: string;
    metadata?: UserMetadata;
    // _links: any;
}

interface Metadata {
    created: string;
    tenant: Tenant;
}

type UserMetadata = Metadata;

interface GroupMetadata extends Metadata {
    allowed_actions: Array<'read' | 'add' | 'update' | 'delete'>;
}

type PreferredLogin = 'email' | 'phone';

type Tenant = 'prod';

export interface UserGroup {
    id: string;
    name: string;
    description: string;
    assigned_roles: Array<AssignedRole>;
    metadata?: GroupMetadata;
    _links?: any;
}

export interface AssignedRole {
    role_id: string;
    scope: Scope;
}

export interface Scope {
    identifiers: Array<string>;
    identifier_type: ScopeType;
}

export interface DisplayedGroupRole {
    id: string;
    label: string;
    type: string;
}

export interface UserGroupRole {
    id: string;
    name: string;
    scope_type: ScopeType;
    key: string;
}

export interface Tag {
    id: string;
    type: string;
    name: string;
    accountId: string;
}

export type ScopeType = 'tag' | 'account';

export const allowedActions = ['read', 'update', 'add', 'delete'] as const;
export type AllowedAction = (typeof allowedActions)[number];
export const isAllowedAction = (bar: string): bar is AllowedAction =>
    (allowedActions as unknown as string[]).includes(bar);
