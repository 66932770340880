import { useIntl } from 'react-intl';
import CreateUser from '../../../../assets/create_user.png';
import { CounterList } from '../CounterList';

export const CreateUserExplained = () => {
    const intl = useIntl();
    const title = intl.formatMessage({ id: `infoDialog.features.inviteUser` });

    return (
        <div className={'row'} data-testid={'Create User'}>
            <div className={'col-xs-12'}>
                <h4>{title}</h4>
                <div className={`margin-bottom-25`}>
                    <img src={CreateUser} alt={'tdv'} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                </div>
                <CounterList chapter={'createUser'} count={4} />
            </div>
        </div>
    );
};
