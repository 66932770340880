import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { INFO_TRACKING_ACTIONS, TRACKING_CATEGORIES, trackingAttributes } from '../../tracking/gtm-helper';
import { Chapter } from '../app/infoModal/Chapter';
import { ServiceInfoPopoverLinkItem } from '../app/infoModal/ServiceInfoPopoverLinkItem';

import { setActiveInfoModalChapter } from '../../routes/actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

export const InfoButton = (props: { appTitle: string }) => {
    const { appTitle } = props;

    const location = useLocation();
    const params = useParams();
    const push = useNavigate();

    const showInfoModal = (chapter: string) => setActiveInfoModalChapter(chapter, { location, params, push });

    const renderPopover = () => (
        <ActionBarItem.Popover className={'myItemPopover'} title={appTitle}>
            <ul className={'ServiceInfoPopoverContent list-unstyled'}>
                <ServiceInfoPopoverLinkItem
                    icon={'rioglyph-hand-right'}
                    messageId={'welcome.message'}
                    onClick={() => showInfoModal(Chapter.WELCOME)}
                    gaEventAction={INFO_TRACKING_ACTIONS.WELCOME_BUTTON_CLICKED}
                />
                <ServiceInfoPopoverLinkItem
                    icon={'rioglyph-question-sign'}
                    messageId={'concepts.message'}
                    onClick={() => showInfoModal(Chapter.GROUPS)}
                    gaEventAction={INFO_TRACKING_ACTIONS.GROUPS_EXPLANATION_BUTTON_CLICKED}
                />
                <ServiceInfoPopoverLinkItem
                    icon={'rioglyph-question-sign'}
                    messageId={'functions.message'}
                    onClick={() => showInfoModal(Chapter.INVITE_USER)}
                    gaEventAction={INFO_TRACKING_ACTIONS.FUNCTIONS_BUTTON_CLICKED}
                />
            </ul>
        </ActionBarItem.Popover>
    );

    return (
        <>
            <ActionBarItem className={'myItem'} id={'roleDescription'}>
                <ActionBarItem.Icon>
                    <span
                        className={'icon rioglyph rioglyph-info-sign'}
                        {...trackingAttributes(
                            TRACKING_CATEGORIES.USERADMIN_INFO,
                            INFO_TRACKING_ACTIONS.INFO_BUTTON_CLICKED
                        )}
                    />
                </ActionBarItem.Icon>
                {renderPopover()}
            </ActionBarItem>
        </>
    );
};
