import { getQueryParameter, QUERY_PARAMETER } from './routeParser';
import { Chapter } from '../features/app/infoModal/Chapter';
import { RouteComponentProps } from './routerUtils';
import { GROUP_ID_PATH_PARAM, USER_ID_PATH_PARAM } from './routes';

export const selectInfoModalChapter = (props: RouteComponentProps): Chapter | null => {
    const queryParameterValue = getQueryParameter(props.location.search, QUERY_PARAMETER.INFO_MODAL);
    if (queryParameterValue) {
        const chapterKey = `infoDialog.${queryParameterValue}` as Chapter;
        return Object.values(Chapter).includes(chapterKey) ? chapterKey : Chapter.WELCOME;
    } else {
        return null;
    }
};

export const selectShowCreateUserModal = (props: RouteComponentProps): boolean => {
    const queryParameterValue = getQueryParameter(props.location.search, QUERY_PARAMETER.CREATE_USER_MODAL);
    return !!queryParameterValue;
};

export const selectShowCreateGroupModal = (props: RouteComponentProps): boolean => {
    const queryParameterValue = getQueryParameter(props.location.search, QUERY_PARAMETER.CREATE_GROUP_MODAL);
    return !!queryParameterValue;
};

export const selectUserIdOfSelectedUser = (props: RouteComponentProps): string | null => {
    return getValueOrNull(props, USER_ID_PATH_PARAM);
};

export const selectGroupIdOfSelectedGroup = (props: RouteComponentProps): string | null => {
    return getValueOrNull(props, GROUP_ID_PATH_PARAM);
};

function getValueOrNull(props: RouteComponentProps, key: string) {
    return (props.params && props.params[key]) ?? null;
}

export const selectUserSearch = (props: RouteComponentProps): string => {
    return getQueryParameter(props.location.search, QUERY_PARAMETER.USER_SEARCH) || '';
};

export const selectUserSort = (props: RouteComponentProps): string => {
    return getQueryParameter(props.location.search, QUERY_PARAMETER.USER_SORT) || '';
};

export const selectGroupSearch = (props: RouteComponentProps): string => {
    return getQueryParameter(props.location.search, QUERY_PARAMETER.GROUP_SEARCH) || '';
};

export const selectGroupSort = (props: RouteComponentProps): string => {
    return getQueryParameter(props.location.search, QUERY_PARAMETER.GROUP_SORT) || '';
};
