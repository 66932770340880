import React from 'react';
import { FormattedMessage } from 'react-intl';

import { connect } from 'react-redux';
import { InfoDialogMenu } from './InfoDialogMenu';
import { InfoPages } from './InfoPages';
import { Chapter } from './Chapter';
import { selectInfoModalChapter } from '../../../routes/selector';
import { closeInfoModal, setActiveInfoModalChapter } from '../../../routes/actions';
import SplitDialog from '@rio-cloud/rio-uikit/SplitDialog';
import { RouteComponentProps, withRouter } from '../../../routes/routerUtils';

interface StateProps {
    setActiveChapter: (chapter: any) => void;
    chapter: Chapter | null;
}

interface DispatchProps {
    closeInfoModal: () => void;
}

export const InfoModalUnconnected = (props: StateProps & DispatchProps) => {
    const { chapter, setActiveChapter, closeInfoModal } = props;

    const handleSelectChapter = (event: any) => {
        event.preventDefault();
        setActiveChapter(event.target.getAttribute('data-name'));
    };

    return (
        <SplitDialog
            show={Boolean(chapter)}
            title={<FormattedMessage id={'infoDialog.title'} />}
            leftContent={<InfoDialogMenu chapter={chapter ?? Chapter.WELCOME} onSelectChapter={handleSelectChapter} />}
            rightContent={chapter ? <InfoPages chapter={chapter} /> : <React.Fragment />}
            onClose={() => closeInfoModal()}
            bsSize={'lg'}
        />
    );
};

const mapStateToProps = (_state: any, ownProps: RouteComponentProps): StateProps => ({
    chapter: selectInfoModalChapter(ownProps),
    setActiveChapter: (chapter: string) => setActiveInfoModalChapter(chapter, ownProps),
});

const mapDispatchToProps = (_dispatch: any, ownProps: RouteComponentProps): DispatchProps => ({
    closeInfoModal: () => {
        closeInfoModal(ownProps);
    },
});

export const InfoModal = withRouter(connect(mapStateToProps, mapDispatchToProps)(InfoModalUnconnected));
