import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../configuration/setup/store';
import { configureIsMobile } from '../app/common/responsive';
import { configureRepository } from './settingsRepository';

const tableMode = {
    SPLIT: 'split',
    TABLE: 'table',
} as const;

export type TableMode = (typeof tableMode)[keyof typeof tableMode];

export interface SettingsState {
    groupsListMode: TableMode;
    usersListMode: TableMode;
}

const USERS_TABLE_NAME = 'users';
const GROUPS_TABLE_NAME = 'groups';

const preselectedTableMode = () => (configureIsMobile(window)() ? tableMode.SPLIT : tableMode.TABLE);

const loadInitialTableModeSettings = (tableName: string, repository: any) => {
    const modeFromStore = repository.getTableMode(tableName);
    let currentTableMode: TableMode;
    if (typeof modeFromStore === 'string' && modeFromStore === tableMode.SPLIT) {
        currentTableMode = tableMode.SPLIT;
    } else if (typeof modeFromStore === 'string' && modeFromStore === tableMode.TABLE) {
        currentTableMode = tableMode.TABLE;
    } else {
        currentTableMode = preselectedTableMode();
    }

    if (!modeFromStore) {
        repository.setTableMode(tableName, currentTableMode);
    }
    return currentTableMode;
};

const createInitialState = (repository: any): SettingsState => {
    const currentUsersTableMode = loadInitialTableModeSettings(USERS_TABLE_NAME, repository);
    const currentGroupsTableMode = loadInitialTableModeSettings(GROUPS_TABLE_NAME, repository);

    return {
        groupsListMode: currentGroupsTableMode,
        usersListMode: currentUsersTableMode,
    };
};

const settingsRepository = configureRepository(window);

const initialState: SettingsState = createInitialState(settingsRepository);

const settingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        changeGroupsListMode: (state, action: PayloadAction<TableMode>) => {
            settingsRepository.setTableMode(GROUPS_TABLE_NAME, action.payload);
            state.groupsListMode = action.payload;
        },
        changeUsersListMode: (state, action: PayloadAction<TableMode>) => {
            settingsRepository.setTableMode(USERS_TABLE_NAME, action.payload);
            state.usersListMode = action.payload;
        },
    },
});

export const { changeUsersListMode, changeGroupsListMode } = settingsSlice.actions;

export default settingsSlice.reducer;

// SELECTORS
export const getUsersListMode = (state: RootState) => state.setting.usersListMode;
export const getGroupsListMode = (state: RootState) => state.setting.groupsListMode;
