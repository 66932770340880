import { FormattedMessage } from 'react-intl';
import { InfoTrackingAction, TRACKING_CATEGORIES, trackingAttributes } from '../../../tracking/gtm-helper';

interface Props {
    icon: string;
    messageId: string;
    href?: string;
    onClick: () => void;
    gaEventAction: InfoTrackingAction;
}

export const ServiceInfoPopoverLinkItem = ({ icon, messageId, href, onClick, gaEventAction }: Props) => (
    <li>
        <div className={'display-flex align-items-baseline padding-top-5 padding-bottom-5'}>
            <div className={'display-flex justify-content-center width-20 margin-right-5'}>
                {icon && <span className={`rioglyph ${icon} text-size-large`} />}
            </div>
            <a
                className={'display-block cursor-pointer text-decoration-none width-0 flex-1-0'}
                onClick={onClick}
                href={href}
                {...trackingAttributes(TRACKING_CATEGORIES.USERADMIN_INFO, gaEventAction)}
            >
                <span>
                    <FormattedMessage id={messageId} />
                </span>
            </a>
        </div>
    </li>
);
