import { AvailableRole } from './roleDescriptionSlice';

export type RoleAccess =
    | 'roles.description.fullAccess'
    | 'roles.description.restrictedAccess'
    | 'roles.description.noAccess';

export interface ServiceDescription {
    service: string;
    description: {
        access: RoleAccess;
        details?: string;
    };
}

interface BaseContentData {
    key: string;
    titleId: string;
}

export interface RoleContentData extends BaseContentData {
    generalDescription?: string;
    scopeType?: string;
    serviceDescriptions?: ServiceDescription[];
    adminServiceDescriptions?: ServiceDescription[];
}

export type RoleContentType = { [key in AvailableRole]: RoleContentData };

export const RoleContent: RoleContentType = {
    Overview: {
        key: 'Overview',
        titleId: 'roles.names.Overview',
    },
    Administrator: {
        key: 'Administrator',
        titleId: 'roles.names.rio.customer.base.FleetAdmin',
        generalDescription: 'roles.names.rio.customer.description.Administrator',
        scopeType: 'roles.description.accountBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
        ],
        adminServiceDescriptions: [
            {
                service: 'roles.services.userAdmin',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.driverAdmin',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.vehicleAdmin',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
        ],
    },
    FleetManager: {
        key: 'Fleet Manager',
        titleId: 'roles.names.FleetManager',
        generalDescription: 'roles.names.rio.customer.description.FleetManager',
        scopeType: 'roles.description.accountBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
        ],
        adminServiceDescriptions: [
            {
                service: 'roles.services.userAdmin',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.driverAdmin',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.vehicleAdmin',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
        ],
    },
    Fleetuser: {
        key: 'Fleet User',
        titleId: 'roles.names.rio.customer.base.FleetUser',
        generalDescription: 'roles.names.rio.customer.description.FleetUser',
        scopeType: 'roles.description.tagBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.cannotControlCharging',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
        ],
    },
    WorkshopManager: {
        key: 'Workshop Manager',
        titleId: 'roles.names.WorkshopManager',
        generalDescription: 'roles.names.rio.customer.description.WorkshopManager',
        scopeType: 'roles.description.tagBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.viewMap',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.cannotBookServices',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.cannotControlCharging',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
        ],
    },
    Dispatcher: {
        key: 'Dispatcher',
        titleId: 'roles.names.Dispatcher',
        generalDescription: 'roles.names.rio.customer.description.Dispatcher',
        scopeType: 'roles.description.tagBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.dispatcher.tachograph',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.cannotBookServices',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
        ],
    },
    HumanResources: {
        key: 'Human Resources',
        titleId: 'roles.names.HumanResources',
        generalDescription: 'roles.names.rio.customer.description.HumanResources',
        scopeType: 'roles.description.tagBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.humanresources.tachograph',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.cannotBookServices',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
        ],
    },
    Shipper: {
        key: 'Shipper',
        titleId: 'roles.names.Shipper',
        generalDescription: 'roles.names.rio.customer.description.Shipper',
        scopeType: 'roles.description.tagBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.viewMap',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
        ],
    },
    Driver: {
        key: 'Driver',
        titleId: 'roles.names.rio.orderconnect.Driver',
        generalDescription: 'roles.names.rio.customer.description.Driver',
        scopeType: 'roles.description.tagBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.cannotBookServices',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
        ],
    },
    DriverTrainer: {
        key: 'Driver Trainer',
        titleId: 'roles.names.rio.perform.CoDriverTrainer',
        generalDescription: 'roles.names.rio.customer.description.CoDriverTrainer',
        scopeType: 'roles.description.accountBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.restrictedAccess',
                    details: 'roles.description.restriction.cannotBookServices',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
        ],
    },
    BookingExpert: {
        key: 'Booking Expert',
        titleId: 'roles.names.rio.mds.BookingExpert',
        generalDescription: 'roles.names.rio.mds.description.BookingExpert',
        scopeType: 'roles.description.accountBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
        ],
    },
    AnalysisExpert: {
        key: 'Analysis Expert',
        titleId: 'roles.names.rio.mds.AnalysisExpert',
        generalDescription: 'roles.names.rio.mds.description.AnalysisExpert',
        scopeType: 'roles.description.accountBased',
        serviceDescriptions: [
            {
                service: 'roles.services.pdc',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.diagnostics',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.perform',
                description: {
                    access: 'roles.description.fullAccess',
                },
            },
            {
                service: 'roles.services.fleetMonitor',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.timed',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.serviceCare',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.tacho',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.marketplace',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.eManager',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
            {
                service: 'roles.services.mannow',
                description: {
                    access: 'roles.description.noAccess',
                },
            },
        ],
    },
};
