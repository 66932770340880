import { isEmpty } from 'ramda';
import { getPersistedNameOfSelectedGroup, getPersistedDescriptionOfSelectedGroup } from '../../appSelectors';
import {
    getGroupDescriptionModification,
    getGroupModifications,
    getGroupNameModification,
} from './groupSidebarSelectors';
import { RootState } from '../../../../configuration/setup/store';

export const getCurrentGroupName = (state: RootState) =>
    getGroupNameModification(state) === '' ? getPersistedNameOfSelectedGroup(state) : getGroupNameModification(state);

export const getCurrentGroupDescription = (state: RootState) =>
    getGroupDescriptionModification(state) === ''
        ? getPersistedDescriptionOfSelectedGroup(state)
        : getGroupDescriptionModification(state);

export const groupSidebarHasChanges = (state: RootState) => {
    const groupModifications = getGroupModifications(state);
    if (!groupModifications) {
        return false;
    }
    const roleChanges =
        !isEmpty(groupModifications.restrictionsToAdd) ||
        !isEmpty(groupModifications.restrictionsToRemove) ||
        !isEmpty(groupModifications.rolesToAdd) ||
        !isEmpty(groupModifications.rolesToRemove);

    const fieldChanges = groupModifications.newGroupDescription !== '' || groupModifications.newGroupName !== '';

    return roleChanges || fieldChanges;
};
