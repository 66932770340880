import { Provider } from 'react-redux';

import { main } from './configuration';

import { config } from './config';
import { createRoot } from 'react-dom/client';
import { store } from './configuration/setup/store';
import { handleLoginRedirect } from './configuration/login/redirect';
import { User } from 'oidc-client-ts';
import { InvalidLogin } from './components/invalidLogin';
import { DEFAULT_LOCALE } from './configuration/lang/lang';
import { IntlProvider } from 'react-intl';
import { useDisplayMessages, useLocale } from './configuration/lang/langSlice';
import ErrorFallback from './components/ErrorFallback';
import { RouterProvider } from 'react-router';
import { router } from './routes/Router';
import { ErrorBoundary } from './components/ErrorBoundary';
import { ConfigCatProvider, createConsoleLogger, DataGovernance, PollingMode } from 'configcat-react';

const App = () => {
    const userLocale = useLocale();
    const displayMessages = useDisplayMessages();

    if (!(displayMessages && userLocale)) {
        return null;
    }

    return (
        <IntlProvider defaultLocale={DEFAULT_LOCALE} key={userLocale} locale={userLocale} messages={displayMessages}>
            <ErrorBoundary fallback={<ErrorFallback />}>
                <ConfigCatProvider
                    sdkKey={config.configCat.apiKey}
                    pollingMode={PollingMode.AutoPoll}
                    options={{
                        pollIntervalSeconds: config.configCat.pollIntervalSeconds,
                        logger: createConsoleLogger(config.configCat.logLevel),
                        dataGovernance: DataGovernance.EuOnly,
                    }}
                >
                    <RouterProvider router={router} />
                </ConfigCatProvider>
            </ErrorBoundary>
        </IntlProvider>
    );
};

const renderApplication = (user: User) => {
    const container = document.getElementById('root') as HTMLElement;
    const root = createRoot(container);

    let showApp = true;
    try {
        showApp = user ? user.profile.sub.startsWith('prod-rio-users:') : true;
    } catch (e) {
        console.error(e);
    }

    if (showApp)
        // Note that we need to use the base "Router" with a "hash" history
        // because the "HashRouter" doesn't allow handing in a history
        // from the outside. So this is effectively a "HashRouter" despite
        // that not being obvious here
        root.render(
            <Provider store={store}>
                <App />
            </Provider>
        );
    else {
        root.render(<InvalidLogin />);
    }
};

if (window.location.href.startsWith(config.login.redirectUri as string)) {
    handleLoginRedirect();
} else {
    main(renderApplication).catch(console.error);
}
