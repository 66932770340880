import { FormattedMessage, useIntl } from 'react-intl';
import { Link, NavLink } from 'react-router';
import { DefaultUserMenu } from '@rio-cloud/rio-user-menu-component';
import { RioNotifications } from '@rio-cloud/rio-notifications-component';
import ApplicationHeader from '@rio-cloud/rio-uikit/ApplicationHeader';
import IframeResizer from 'iframe-resizer-react';

import { config } from '../../config';
import { routes } from '../../routes/routes';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getSelectedGroupId, getSelectedUserId } from '../app/appSelectors';
import { accountType, getAccountType } from '../../configuration/login/loginSlice';
import { getIdToken } from '../../configuration/tokenHandling/tokenHandlingSlice';
import ActionBarItem from '@rio-cloud/rio-uikit/ActionBarItem';
import { InfoButton } from './InfoButton';
import { LogisticsMenu } from './LogisticsMenu';

const AppHeader = () => {
    const environment = import.meta.env.PROD ? 'production' : 'local';

    const selectedUserId = useAppSelector(getSelectedUserId);
    const selectedGroupId = useAppSelector(getSelectedGroupId);
    const isCarrierAccount = useAppSelector((state) => getAccountType(state) === accountType.CARRIER);
    const idToken = useAppSelector(getIdToken)!;

    const intl = useIntl();

    // -----------------------------------
    // App navigator
    const url = config.backend.MENU_SERVICE;
    const appNavigator = isCarrierAccount ? <IframeResizer className={'iFrameResizer'} src={url} /> : <LogisticsMenu />;

    // -----------------------------------
    // Navigation items
    const usersNavItem = {
        key: 'users',
        route: (
            <NavLink to={selectedUserId ? `${routes.DEFAULT}/${selectedUserId}` : routes.DEFAULT} viewTransition>
                <FormattedMessage id={'users.subModuleName'} />
            </NavLink>
        ),
    };

    const groupsNavItem = {
        key: 'groups',
        route: (
            <NavLink to={selectedGroupId ? `${routes.GROUPS}/${selectedGroupId}` : routes.GROUPS} viewTransition>
                <FormattedMessage id={'groups.subModuleName'} />
            </NavLink>
        ),
    };

    const navItems = isCarrierAccount ? [usersNavItem, groupsNavItem] : [usersNavItem];

    // -----------------------------------
    // Action bar items
    const postLogout = config.postLogoutRedirectUri
        ? {
              redirectUri: config.postLogoutRedirectUri,
              idTokenHint: idToken,
          }
        : undefined;

    const notifications = <RioNotifications />;
    const userMenu = <DefaultUserMenu environment={environment} postLogout={postLogout} />;
    const infoButton = <InfoButton appTitle={intl.formatMessage({ id: 'applicationmenu.useradmin.title' })} />;
    const accountSettings = (
        <ActionBarItem id={'noPopoverComponent'} hidePopoverOnClick={false} popoverWidth={400}>
            <ActionBarItem.Icon>
                <NavLink to={'/settings'}>
                    <span className={'icon rioglyph rioglyph-cog'} data-cy={'account-settings-icon'} />
                </NavLink>
            </ActionBarItem.Icon>
        </ActionBarItem>
    );

    const actionBarItems = isCarrierAccount ? [accountSettings, infoButton, notifications, userMenu] : [userMenu];

    const homeRoute = isCarrierAccount ? (
        <Link to={'https://home.rio.cloud'} />
    ) : (
        <Link to={'https://users.iam.rio.cloud'} />
    );

    return (
        <ApplicationHeader
            label={intl.formatMessage({ id: 'applicationmenu.useradmin.title' })}
            appNavigator={appNavigator}
            homeRoute={homeRoute}
            navItems={navItems}
            actionBarItems={actionBarItems}
        />
    );
};

export default AppHeader;
