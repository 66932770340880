import { backendUrls } from './config';
import {
    mapApiGroupToUserGroup,
    mapApiRoleToUserGroupRole,
    mapApiTagToTag,
    mapApiUserToUser,
    mapToAllowedActions,
    mapToGroups,
    mapToLoadMore,
    mapToUsers,
} from './mapper';
import { User, UserGroup, UserGroupRole } from '../app/appTypes';
import { deleteRequest, getRequest, putRequest } from './requests';
import { DEFAULT_LIMIT_FOR_BACKEND_FETCHES } from '../../constants';
import { GroupsAndLoadMore, Tag, UsersAndLoadMore } from './types';

import { UserToInvite } from '../app/users/invite/inviteUserTypes';
import { reportErrorToSentry } from '../../configuration/setup/sentry';
import { config } from '../../config';
import { accessToken } from '../../configuration/tokenHandling/accessToken';
import {
    AccountByIdApiResponseFull,
    AccountSetting,
    decodeGroupsApiResponse,
    decodeRoleApiResponse,
    decodeSingleGroupApiResponse,
    decodeSingleUserApiResponse,
    decodeTagApiResponse,
    decodeUsersApiResponse,
} from './apiSchema';

const HTTP_STATUS_CREATED = 201;

const onRejected = (error?: Error): Promise<never> => (error ? Promise.reject(error) : Promise.reject());

const okOrReject = (response: Response = {} as Response, expectedStatusCode?: number): Response | Promise<any> => {
    if (expectedStatusCode && response.status !== expectedStatusCode) {
        return Promise.reject(new Error('UNEXPECTED_STATUS_CODE'));
    } else if (expectedStatusCode && response.status === expectedStatusCode) {
        return response;
    }

    if (response.status === 401) {
        return Promise.reject(new Error('UNAUTHENTICATED'));
    } else if (response.status === 403) {
        return Promise.reject(new Error('ACCESS_DENIED'));
    } else if (response.ok) {
        return response;
    } else {
        reportErrorToSentry(new Error(`${response.status} Backend error: ${response.statusText}`));
    }
    return Promise.reject(new Error('Backend error'));
};

const jsonOrReject = (response: Response = {} as Response): Error | Promise<any> => {
    return response.json().catch((error) => {
        reportErrorToSentry(new Error(`${response.status} Invalid payload: ${error.message}`));
    });
};

export class CustomFetchError extends Error {
    readonly status: number;
    readonly code: string | undefined;
    readonly detail: string | undefined;

    constructor(m: string, n: number, c?: string, d?: string) {
        super(m);
        this.status = n;
        this.code = c;
        this.detail = d;

        // Set the prototype explicitly.
        Object.setPrototypeOf(this, CustomFetchError.prototype);
    }
}

// USERS
export const fetchUser = (userId: string): Promise<User> =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/users/${userId}`, getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleUserApiResponse)
        .then(mapApiUserToUser)
        .catch(onRejected);

export const fetchUsers = (data: {
    limit?: number;
    rawUrl?: string;
    search?: string;
    showManagedGroups?: boolean; // TODO only relevant for debugging. Do we need it?
    userSortBy?: string;
    userSortByAsc?: boolean;
}): Promise<UsersAndLoadMore> => {
    const {
        limit = DEFAULT_LIMIT_FOR_BACKEND_FETCHES,
        rawUrl,
        search,
        showManagedGroups = false,
        userSortBy = '',
        userSortByAsc = true,
    } = data;

    const go = (url: string) =>
        fetch(url, getRequest())
            .then(okOrReject)
            .then(jsonOrReject)
            .then(decodeUsersApiResponse)
            .then((response) => {
                const users = mapToUsers(response);
                const loadMore = mapToLoadMore(response);
                const allowedActions = mapToAllowedActions(response.allowed_actions);
                return {
                    users: users,
                    loadMoreLink: loadMore ? loadMore : null,
                    allowedActions: allowedActions,
                };
            })
            .catch(onRejected);

    if (rawUrl) {
        return go(rawUrl);
    }

    const params = [
        search ? `q=${encodeURIComponent(search)}` : null,
        limit ? `limit=${encodeURIComponent(limit)}` : null,
        userSortBy ? `sort=${userSortByAsc ? '+' : '-'}${encodeURIComponent(userSortBy)}` : null,
        showManagedGroups ? `showManagedGroups=true` : null,
    ]
        .filter(Boolean)
        .join('&');

    return go(`${backendUrls.USER_ADMINISTRATION}/users` + (params ? `?${params}` : ''));
};

export const inviteUser = (user: UserToInvite): Promise<User> =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/users/${user.id}`, putRequest(user, true)) // TODO do we really want to create a uuid here?
        .then((res) => {
            return okOrReject(res, HTTP_STATUS_CREATED);
        })
        .then(jsonOrReject)
        .then(decodeSingleUserApiResponse)
        .then(mapApiUserToUser)
        .catch(onRejected);

export const saveUser = (user: User): Promise<User> =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/users/${user.id}`, putRequest(user))
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleUserApiResponse)
        .then(mapApiUserToUser)
        .catch(onRejected);

export const deleteUser = (userId: string) =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/users/${userId}`, deleteRequest())
        .then(okOrReject)
        .then(() => {}) // we don't care about response for delete
        .catch(onRejected);

// GROUPS
export const fetchGroup = (groupId: string): Promise<UserGroup> =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/groups/${groupId}`, getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleGroupApiResponse)
        .then(mapApiGroupToUserGroup)
        .catch(onRejected);

export const fetchGroups = (data: {
    limit?: number;
    rawUrl?: string;
    search?: string;
    showManagedGroups?: boolean; // TODO only relevant for debugging. Do we need it?
    groupSortBy?: string;
    groupSortByAsc?: boolean;
}): Promise<GroupsAndLoadMore> => {
    const {
        limit = DEFAULT_LIMIT_FOR_BACKEND_FETCHES,
        rawUrl,
        search,
        showManagedGroups = false,
        groupSortBy = '',
        groupSortByAsc = true,
    } = data;

    const go = (url: string) =>
        fetch(url, getRequest())
            .then(okOrReject)
            .then(jsonOrReject)
            .then(decodeGroupsApiResponse)
            .then((response) => {
                const groups = mapToGroups(response);
                const loadMore = mapToLoadMore(response);
                const allowedActions = mapToAllowedActions(response.allowed_actions);
                return {
                    groups: groups,
                    loadMoreLink: loadMore ? loadMore : null,
                    allowedActions: allowedActions,
                };
            })
            .catch(onRejected);

    if (rawUrl) {
        return go(rawUrl);
    }

    const params = [
        search ? `q=${encodeURIComponent(search)}` : null,
        limit ? `limit=${encodeURIComponent(limit)}` : null,
        groupSortBy ? `sort=${groupSortByAsc ? '+' : '-'}${encodeURIComponent(groupSortBy)}` : null,
        showManagedGroups ? `showManagedGroups=true` : null,
    ]
        .filter(Boolean)
        .join('&');

    return go(`${backendUrls.USER_ADMINISTRATION}/groups` + (params ? `?${params}` : ''));
};

export const createGroup = (group: UserGroup) =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/groups/${group.id}`, putRequest(group))
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleGroupApiResponse)
        .then(mapApiGroupToUserGroup)
        .catch(onRejected);

export const saveGroup = (group: UserGroup): Promise<UserGroup> =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/groups/${group.id}`, putRequest(group))
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeSingleGroupApiResponse)
        .then(mapApiGroupToUserGroup)
        .catch(onRejected);

export const deleteGroup = (groupId: string) =>
    fetch(`${backendUrls.USER_ADMINISTRATION}/groups/${groupId}`, deleteRequest())
        .then(okOrReject)
        .then(() => {}) // we don't care about response for delete
        .catch(onRejected);

// ROLES
export const fetchRoles = (data?: { limit: number }): Promise<Array<UserGroupRole>> => {
    const { limit } = data ? data : { limit: DEFAULT_LIMIT_FOR_BACKEND_FETCHES };
    return fetch(`${backendUrls.USER_ADMINISTRATION}/roles` + (limit ? `?limit=${limit}` : ''), getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .then(decodeRoleApiResponse)
        .then((res) => res.items.map(mapApiRoleToUserGroupRole))
        .catch(onRejected);
};

// TAGS
export const fetchTags = async (): Promise<Array<Tag>> => {
    let nextLink = `${backendUrls.TAGS_SERVICE}/tags`;
    let tags: Tag[] = [];

    while (nextLink !== null && nextLink !== undefined) {
        const apiTagResponse = await fetch(nextLink, getRequest())
            .then(okOrReject)
            .then(jsonOrReject)
            .catch(onRejected);
        nextLink = apiTagResponse._links?.next?.href;
        tags = tags.concat(decodeTagApiResponse(apiTagResponse).items.map(mapApiTagToTag) as Tag[]);
    }
    return tags;
};

// ACCOUNTS
export const fetchAccountById = async (accountId: string): Promise<AccountByIdApiResponseFull> =>
    fetch(`${backendUrls.ACCOUNTS_SERVICE}/${accountId}`, getRequest())
        .then(okOrReject)
        .then(jsonOrReject)
        .catch(onRejected);

export const updateAccount = async (data: AccountByIdApiResponseFull): Promise<void> => {
    if (!data) {
        throw new CustomFetchError('account data is required', 0);
    }

    const response = await fetch(`${config.backend.ACCOUNTS_SERVICE}/${data.id}`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
        },
    });

    if (!response.ok) {
        // Accounts API uses Problem JSON, so body should always be there
        const errorBody = await response.json();
        throw new CustomFetchError(
            'HTTP error when updating account',
            response.status,
            errorBody.code,
            errorBody.detail
        );
    }
};

// Account settings
export const fetchUserSelfRegistrationAccountSettings = async (accountId: string): Promise<AccountSetting> => {
    if (!accountId) {
        throw new CustomFetchError('accountId is required', 1);
    }

    const response = await fetch(`${config.backend.ACCOUNTS_SERVICE}/${accountId}/settings/user-self-registration`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
        },
    });

    return await response.json();
};

export const updateUserSelfRegistrationSetting = async (accountId: string, data: AccountSetting): Promise<void> => {
    if (!accountId) {
        throw new CustomFetchError('accountId is required', 1);
    }
    if (!data) {
        throw new CustomFetchError('account data is required', 0);
    }

    const response = await fetch(`${config.backend.ACCOUNTS_SERVICE}/${accountId}/settings/user-self-registration`, {
        method: 'PUT',
        body: JSON.stringify(data),
        headers: {
            'Content-Type': 'application/json; charset=utf-8',
            Authorization: `Bearer ${accessToken.getAccessToken()}`,
        },
    });

    if (!response.ok) {
        throw new CustomFetchError('HTTP error when updating account', response.status);
    }
};
