import { useRef } from 'react';
import { Outlet } from 'react-router';
import ApplicationLayout from '@rio-cloud/rio-uikit/ApplicationLayout';
import NotificationsContainer from '@rio-cloud/rio-uikit/NotificationsContainer';

// import RouteUpdater from '../routes/RouteUpdater';
import SessionExpiredInfo from '../components/SessionExpiredInfo';
import AppHeader from '../features/header/AppHeader';
import { AppContext } from './AppContext';
import { InfoModal } from '../features/app/infoModal/InfoModal';

import './App.css';
import { NotificationsTrigger } from '../features/notifications/Notifications';

const AppLayout = () => {
    const sidebarRef = useRef<HTMLDivElement>(null);

    const inIframe = window.self !== window.top;

    return (
        <AppContext.Provider value={{ sidebarRef }}>
            <ApplicationLayout data-testid="app-layout">
                {!inIframe && (
                    <ApplicationLayout.Header>
                        <AppHeader />
                    </ApplicationLayout.Header>
                )}
                <ApplicationLayout.Sidebar className="right" ref={sidebarRef} />
                <ApplicationLayout.Body>
                    <NotificationsTrigger />
                    <NotificationsContainer />
                    <SessionExpiredInfo />
                    <InfoModal />
                    <Outlet />
                    {/*<RouteUpdater />*/}
                </ApplicationLayout.Body>
            </ApplicationLayout>
        </AppContext.Provider>
    );
};

export default AppLayout;
