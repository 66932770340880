import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Notification from '@rio-cloud/rio-uikit/Notification';
import { ERROR_NOTIFICATION, SUCCESS_NOTIFICATION } from './notificationType';
import { useAppSelector } from '../../configuration/setup/hooks';
import { getNotificationKey, getNotificationType } from './notificationSlice';

/**
 * NotificationsTrigger is a legacy component. Use UiKit Notification directly instead.
 *
 * @constructor
 */
export const NotificationsTrigger = () => {
    const notificationKey = useAppSelector(getNotificationKey);
    const notificationType = useAppSelector(getNotificationType);

    useEffect(() => {
        if (notificationKey && notificationType) {
            switch (notificationType) {
                case ERROR_NOTIFICATION:
                    Notification.error(<FormattedMessage id={notificationKey} />);
                    break;
                case SUCCESS_NOTIFICATION:
                    Notification.success(<FormattedMessage id={notificationKey} />);
                    break;
                default:
                    break;
            }
        }
    }, [notificationKey, notificationType]);

    return null;
};
