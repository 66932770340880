import { createHashRouter, createRoutesFromElements, Route } from 'react-router';
import { Navigate } from 'react-router';

import AppLayout from '../layout/AppLayout';
import RouteErrorFallback from '../components/RouteErrorFallback';
import { suspendPageLoad } from './suspendPageLoad';
import { GROUP_ID_PATH_PARAM, routes, USER_ID_PATH_PARAM } from './routes';

// Lazy load pages for better performance and automatically split the bundle accordingly
const Users = suspendPageLoad(() => import('../pages/Users'));
const UserSidebarLoader = suspendPageLoad(() => import('../features/app/users/usersidebar/UserSidebarLoader'));
const Groups = suspendPageLoad(() => import('../pages/Groups'));
const GroupSidebarLoader = suspendPageLoad(() => import('../features/app/groups/groupsidebar/GroupSidebarLoader'));
const LegacySettings = suspendPageLoad(() => import('../pages/LegacySettings'));
const CompanySettings = suspendPageLoad(() => import('../pages/CompanySettings'));

const routesFromElements = createRoutesFromElements(
    <Route element={<AppLayout />} errorElement={<RouteErrorFallback />}>
        <Route path={routes.DEFAULT} element={<Users />}>
            <Route path={`:${USER_ID_PATH_PARAM}`} element={<UserSidebarLoader />} />
        </Route>
        <Route path={routes.GROUPS} element={<Groups />}>
            <Route path={`:${GROUP_ID_PATH_PARAM}`} element={<GroupSidebarLoader />} />
        </Route>
        <Route path={routes.SETTINGS} element={<LegacySettings />} />
        <Route path={routes.COMPANY_SETTINGS} element={<CompanySettings />} />

        <Route path="*" element={<Navigate to={routes.DEFAULT} />} />
    </Route>
);

export const router = createHashRouter(routesFromElements);
