import { Chapter } from './Chapter';
import { Welcome } from './content/about/Welcome';
import { FleetGroupExplanation } from './content/concepts/FleetGroupsExplanation';
import { UserGroupExplanation } from './content/concepts/UserGroupExplanation';
import { UserGroupRolesExplanation } from './content/concepts/UserGroupRolesExplanation';
import { CreateUserExplained } from './content/functions/CreateUser';
import { CreateUserGroupExplained } from './content/functions/CreateUserGroup';
import { DeleteUserExplained } from './content/functions/DeleteUser';
import { RestrictUserGroupExplained } from './content/functions/RestrictUserGroupToFleetGroup';
import { DeleteUserGroupExplained } from './content/functions/DeleteUserGroup';
import { CreateUserForDriverExplained } from './content/functions/CreateUserForDriver';
import { DriverSelfRegistrationExplanation } from './content/legacyAccountSettings/DriverSelfRegistrationExplanation';
import { accountType } from '../../../configuration/login/loginSlice';

export interface InfoPagesProps {
    chapter: Chapter;
}

export const InfoPages = ({ chapter }: InfoPagesProps) => {
    switch (chapter) {
        default:
        case Chapter.WELCOME:
            return <Welcome />;
        case Chapter.GROUPS:
            return <UserGroupExplanation accountType={accountType.CARRIER} />;
        case Chapter.SUPPLIER_GROUPS:
            return <UserGroupExplanation accountType={accountType.SUPPLIER} />;
        case Chapter.BUYER_GROUPS:
            return <UserGroupExplanation accountType={accountType.BUYER} />;
        case Chapter.ROLES:
            return <UserGroupRolesExplanation />;
        case Chapter.FLEET:
            return <FleetGroupExplanation />;
        case Chapter.INVITE_USER:
            return <CreateUserExplained />;
        case Chapter.INVITE_USER_FOR_DRIVER:
            return <CreateUserForDriverExplained />;
        case Chapter.DELETE_USER:
            return <DeleteUserExplained />;
        case Chapter.CREATE_USER_GROUP:
            return <CreateUserGroupExplained />;
        case Chapter.DELETE_USER_GROUP:
            return <DeleteUserGroupExplained />;
        case Chapter.RESTRICT_USER_GROUP:
            return <RestrictUserGroupExplained />;
        case Chapter.DRIVER_SELF_REGISTRATION:
            return <DriverSelfRegistrationExplanation />;
    }
};
