import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import Markdown from 'react-markdown';
import WelcomeImage from '../../../../assets/welcome_image.png';

export const Welcome: React.FC = () => {
    const intl = useIntl();

    return (
        <div className={'row'} data-testid={'WelcomePage'}>
            <div className={'col-xs-12'}>
                <h2>
                    <FormattedMessage id={'infoDialog.about.welcome.headline'} />
                </h2>
            </div>
            <div className={'col-xs-12'}>
                <div
                    className={
                        'cursor-col-resize border-style-solid border-width-1 border-color-light margin-bottom-15'
                    }
                >
                    <img src={WelcomeImage} alt="Welcome" style={{ width: '100%', height: '100%' }} />
                </div>
            </div>
            <div className={'col-sm-6'}>
                <div className={'text-size-20'}>
                    <Markdown>{intl.formatMessage({ id: 'infoDialog.about.welcome.subline1' })}</Markdown>
                </div>
                <Markdown>{intl.formatMessage({ id: 'infoDialog.about.welcome.content1' })}</Markdown>
            </div>
            <div className={'col-sm-6'}>
                <div className={'text-size-20'}>
                    <Markdown>{intl.formatMessage({ id: 'infoDialog.about.welcome.subline2' })}</Markdown>
                </div>
                <Markdown>{intl.formatMessage({ id: 'infoDialog.about.welcome.content2' })}</Markdown>
            </div>
        </div>
    );
};
