import { RootState } from '../../../../configuration/setup/store';

export const getGroupModifications = (state: RootState) => state.groupSidebar.modifications;
export const getGroupRolesToAdd = (state: RootState) => state.groupSidebar.modifications.rolesToAdd;
export const getGroupRolesToRemove = (state: RootState) => state.groupSidebar.modifications.rolesToRemove;
export const getGroupRestrictionsToAdd = (state: RootState) => state.groupSidebar.modifications.restrictionsToAdd;
export const getGroupRestrictionsToRemove = (state: RootState) => state.groupSidebar.modifications.restrictionsToRemove;
export const getShowDeleteGroupConfirmation = (state: RootState) => state.groupSidebar.showDeleteConfirmation;
export const getShowEditGroupDialog = (state: RootState) => state.groupSidebar.showEditDialog;
export const getShowGroupConfirmation = (state: RootState) => state.groupSidebar.showConfirmation;

export const getGroupNameModification = (state: RootState) => state.groupSidebar.modifications.newGroupName;
export const getGroupDescriptionModification = (state: RootState) =>
    state.groupSidebar.modifications.newGroupDescription;
