import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RestrictionChange } from '../creategroup/createGroupTypes';
import { SliceActions } from '../../reduxHelper';

export interface GroupSidebarInitialState {
    modifications: {
        rolesToAdd: string[];
        rolesToRemove: string[];
        restrictionsToAdd: RestrictionChange[];
        restrictionsToRemove: RestrictionChange[];

        newGroupName: string;
        newGroupDescription: string;
    };
    showDeleteConfirmation: boolean;
    showEditDialog: boolean;
    showConfirmation: boolean;
}

export const initialState: GroupSidebarInitialState = {
    modifications: {
        rolesToAdd: [],
        rolesToRemove: [],
        restrictionsToAdd: [],
        restrictionsToRemove: [],

        newGroupName: '',
        newGroupDescription: '',
    },
    showDeleteConfirmation: false,
    showEditDialog: false,
    showConfirmation: false,
};

const groupSidebarSlice = createSlice({
    name: 'groupSidebarSlice',
    initialState,
    reducers: {
        addRole(state, action: PayloadAction<string>) {
            const previousRolesToAdd = state.modifications.rolesToAdd;
            state.modifications.rolesToAdd = [...previousRolesToAdd, action.payload];
        },
        removeRole(state, action: PayloadAction<string>) {
            const roleWasNotPersisted = state.modifications.rolesToAdd.includes(action.payload);
            const newRolesToAdd = state.modifications.rolesToAdd.filter((roleId) => roleId !== action.payload);
            const newRolesToRemove = roleWasNotPersisted
                ? state.modifications.rolesToRemove
                : [...state.modifications.rolesToRemove, action.payload];

            state.modifications.rolesToAdd = newRolesToAdd;
            state.modifications.rolesToRemove = newRolesToRemove;
        },
        resurrectRole(state, action: PayloadAction<string>) {
            state.modifications.rolesToRemove = state.modifications.rolesToRemove.filter(
                (roleId) => roleId !== action.payload
            );
        },
        addRestriction(state, action: PayloadAction<RestrictionChange>) {
            const previousRestrictionsToAdd = state.modifications.restrictionsToAdd;
            state.modifications.restrictionsToAdd = [...previousRestrictionsToAdd, action.payload];
        },
        removeRestriction(state, action: PayloadAction<RestrictionChange>) {
            const restrictionWasNotPersisted =
                state.modifications.restrictionsToAdd.filter((restriction) =>
                    compareRelevantFields(restriction, action.payload)
                ).length > 0;

            const newRestrictionsToAdd = state.modifications.restrictionsToAdd.filter(
                (restriction) =>
                    restriction.id !== action.payload.id || restriction.parentId !== action.payload.parentId
            );
            const newRestrictionsToRemove = restrictionWasNotPersisted
                ? state.modifications.restrictionsToRemove
                : [...state.modifications.restrictionsToRemove, action.payload];

            state.modifications.restrictionsToAdd = newRestrictionsToAdd;
            state.modifications.restrictionsToRemove = newRestrictionsToRemove;
        },
        resurrectRestriction(state, action: PayloadAction<RestrictionChange>) {
            state.modifications.restrictionsToRemove = state.modifications.restrictionsToRemove.filter(
                (restriction) => restriction.id !== action.payload.id
            );
        },
        updateGroupProperties(state, action: PayloadAction<{ newGroupName: string; newGroupDescription: string }>) {
            state.modifications.newGroupName = action.payload.newGroupName;
            state.modifications.newGroupDescription = action.payload.newGroupDescription;
        },
        reset() {
            return initialState;
        },
        updateGroupDescription(state, action: PayloadAction<string>) {
            state.modifications.newGroupDescription = action.payload;
        },
        updateGroupName(state, action: PayloadAction<string>) {
            state.modifications.newGroupName = action.payload;
        },
        deleteGroupSuccess(state) {
            state.showConfirmation = false;
        },
        deleteGroupError(state) {
            state.showConfirmation = false;
        },
        showDeleteGroupConfirmation(state, action: PayloadAction<boolean>) {
            state.showDeleteConfirmation = action.payload;
        },
        showEditGroupDialog(state, action: PayloadAction<boolean>) {
            state.showEditDialog = action.payload;
        },
        showGroupConfirmation(state, action: PayloadAction<boolean>) {
            state.showConfirmation = action.payload;
        },
        resetGroupState() {
            return initialState;
        },
        saveGroupSuccess() {},
        saveGroupError() {},
    },
});

const compareRelevantFields = (restriction: RestrictionChange, restrictionToBeRemoved: RestrictionChange) =>
    restriction.id === restrictionToBeRemoved.id && restriction.parentId === restrictionToBeRemoved.parentId;

export type GroupSidebarActionTypes = SliceActions<typeof groupSidebarSlice.actions>;
export default groupSidebarSlice;
