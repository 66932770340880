import { z } from 'zod';

const allowedAction = z.enum(['read', 'update', 'delete', 'add']);

// -----------------------------
// USERS
// -----------------------------
const baseMetadata = z.object({
    created: z.unknown(),
    tenant: z.string(),
});

const user = z.object({
    id: z.string(),
    account_id: z.string(),
    email: z.string().nullable().optional(),
    additional_authentication_factors: z.array(z.string()).optional(),
    phone_number: z.string().nullable().optional(),
    preferred_login: z.string(), // leave this as general as possible here and do actual logic in mapper.ts
    preferred_language: z.string(),
    first_name: z.string(),
    last_name: z.string(),
    group_ids: z.array(z.string()),
    metadata: baseMetadata.optional(),
    subject: z.string().nullable().optional(),
});

const usersApiResponse = z.object({
    items: z.array(user),
    allowed_actions: z.array(z.string()).nullable().optional(),
    _links: z.object({
        next: z.object({ href: z.string() }).optional(),
        self: z.object({ href: z.string() }).optional(),
    }),
});

export type UsersApiResponse = z.infer<typeof usersApiResponse>;
export type ApiUser = z.infer<typeof user>;

export const decodeUsersApiResponse = (parsedObject: unknown): UsersApiResponse => usersApiResponse.parse(parsedObject);

export const decodeSingleUserApiResponse = (parsedObject: unknown): ApiUser => user.parse(parsedObject);

// -----------------------------
// GROUPS
// -----------------------------
const groupsMetadata = baseMetadata.extend({
    allowed_actions: z.array(allowedAction),
});
const groupRole = z.object({
    role_id: z.string(),
    scope: z.object({
        identifier_type: z.enum(['tag', 'account']),
        identifiers: z.array(z.string()),
    }),
});

const group = z.object({
    id: z.string(),
    name: z.string(),
    description: z.string().nullable().optional(),
    assigned_roles: z.array(groupRole),
    metadata: groupsMetadata,
    _links: z.unknown(),
});

const userGroupsApiResponse = z.object({
    items: z.array(group),
    allowed_actions: z.array(z.string()).nullable().optional(),
    _links: z.object({
        next: z.object({ href: z.string() }).optional(),
        self: z.object({ href: z.string() }).optional(),
    }),
});

export type ApiGroup = z.infer<typeof group>;
export type UserGroupsApiResponse = z.infer<typeof userGroupsApiResponse>;

export const decodeGroupsApiResponse = (parsedObject: unknown): UserGroupsApiResponse =>
    userGroupsApiResponse.parse(parsedObject);

export const decodeSingleGroupApiResponse = (parsedObject: unknown): ApiGroup => group.parse(parsedObject);

// -----------------------------
// ROLES
// -----------------------------
const role = z.object({
    id: z.string(),
    name: z.string(),
    scope_type: z.string(),
});

const roleApiResponse = z.object({
    items: z.array(role),
    allowed_actions: z.unknown(),
    _links: z
        .object({
            self: z.object({ href: z.string() }).optional(),
        })
        .optional(),
});

export type ApiRole = z.infer<typeof role>;
export type RoleApiResponse = z.infer<typeof roleApiResponse>;

export const decodeRoleApiResponse = (parsedObject: unknown): RoleApiResponse => roleApiResponse.parse(parsedObject);

// -----------------------------
// Tags
// -----------------------------
const tag = z.object({
    id: z.string(),
    type: z.string(),
    name: z.string(),
    account_id: z.string(),
});

const tagApiResponse = z.object({
    items: z.array(tag),
});

export type ApiTag = z.infer<typeof tag>;
export type TagApiResponse = z.infer<typeof tagApiResponse>;

export const decodeTagApiResponse = (parsedObject: unknown): TagApiResponse => tagApiResponse.parse(parsedObject);

// ACCOUNTS
export const accountByIdApiResponseFull = z.object({
    id: z.string(),
    name: z.string(),
    legal_address: z.object({
        line_1: z.string().optional(),
        line_2: z.string().optional(),
        line_3: z.string().optional(),
        city: z.string().optional(),
        postal_code: z.string().optional(),
        country_code: z.string(),
    }),
    tax_id: z
        .object({
            value: z.string(),
            tax_type: z.string(),
        })
        .optional(),
    external_identifiers: z
        .array(
            z.object({
                type: z.string(),
                value: z.string(),
            })
        )
        .optional(),
    tenant: z.string(),
    created_at: z.string().optional(),
    finalization_date: z.string().optional(),
    logistics_tenant: z.string().optional(),
    tenant_specific_data: z
        .object({
            address_details: z.string().optional(),
            city_id: z.string(),
            neighbourhood: z.string(),
            phone_number: z.string(),
            state: z.string(),
        })
        .optional(),
    registration_channel: z.string().optional(),
    life_cycle_state: z.string().optional(),
    account_type: z.string().optional(),
    contacts: z
        .object({
            general: z
                .object({
                    email: z.string(),
                })
                .optional(),
            billing: z
                .object({
                    email: z.string(),
                })
                .optional(),
        })
        .optional(),
});

export type AccountByIdApiResponseFull = z.infer<typeof accountByIdApiResponseFull>;

export const decodeAccountByIdApiResponseFull = (parsedObject: unknown): AccountByIdApiResponseFull =>
    accountByIdApiResponseFull.parse(parsedObject);

// -----------------------------
// ACCOUNT SETTINGS
// -----------------------------
const accountSetting = z.object({
    id: z.string(), // known: user-self-registration
    group: z.string().optional(),
    values: z.record(z.string(), z.string()), // known key: driver_self_registration
});

export type AccountSetting = z.infer<typeof accountSetting>;
