import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AllowedAction, AppState, Tag, User, UserGroup, UserGroupRole } from './appTypes';
import { find } from 'ramda';
import { SliceActions } from './reduxHelper';
import { useAppSelector } from '../../configuration/setup/hooks';
import { RootState } from '../../configuration/setup/store';

export const initialState: AppState = {
    accessIsForbidden: false,
    allowedGroupActions: [],
    allowedUserActions: [],
    groups: [],
    groupsLoadMoreLink: null,
    roles: [],
    routeInfo: {},
    nextGroup: null,
    selectedGroup: null,
    selectedUser: null,
    serverErrorCode: null,
    sessionWarningAcknowledged: false,
    users: [],
    usersLoadMoreLink: null,
    isLoading: false,
    loadingError: null,
    tags: [],
    lastCreatedUserId: undefined,
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        groupDeleted(state, action: PayloadAction<string>) {
            state.groups = state.groups.filter((group) => group.id !== action.payload);
        },

        groupDeselected(state) {
            state.selectedGroup = null;
        },

        setNextGroup(state, action: PayloadAction<{ group: UserGroup; redirect: boolean } | null>) {
            state.nextGroup = action.payload;
        },

        groupSelected(state, action: PayloadAction<UserGroup>) {
            state.selectedGroup = action.payload;
        },

        groupsFetched(
            state,
            action: PayloadAction<{
                groups: UserGroup[];
                loadMoreLink: string | null;
                allowedActions: AllowedAction[] | null;
            }>
        ) {
            state.allowedGroupActions = action.payload.allowedActions
                ? action.payload.allowedActions
                : state.allowedGroupActions;
            state.groups = action.payload.groups;
            state.groupsLoadMoreLink = action.payload.loadMoreLink;
        },

        rolesFetched(state, action: PayloadAction<UserGroupRole[]>) {
            state.roles = action.payload;
        },

        selectedGroupFetched(state, action: PayloadAction<UserGroup>) {
            state.selectedGroup = action.payload;
        },

        selectedUserFetched(state, action: PayloadAction<User>) {
            state.selectedUser = action.payload;
        },

        sessionWarningDialogClosed(state) {
            state.sessionWarningAcknowledged = true;
        },

        userDeleted(state, action: PayloadAction<string>) {
            state.users = state.users.filter((user) => user.id !== action.payload);
        },

        userDeselected(state) {
            state.selectedUser = null;
        },

        userSelected(state, action: PayloadAction<User>) {
            state.selectedUser = action.payload;
        },

        isLoading(state, action: PayloadAction<{ isLoading: boolean; error?: string | undefined }>) {
            state.isLoading = action.payload.isLoading;
            state.loadingError = action.payload.error;
        },

        usersFetched(
            state,
            action: PayloadAction<{
                users: User[];
                loadMoreLink: string | null;
                allowedActions: AllowedAction[] | null;
            }>
        ) {
            state.users = action.payload.users;
            state.usersLoadMoreLink = action.payload.loadMoreLink;
            state.allowedUserActions = action.payload.allowedActions
                ? action.payload.allowedActions
                : state.allowedGroupActions;
        },

        tagsFetched(state, action: PayloadAction<Tag[]>) {
            state.tags = action.payload;
        },

        addGroup(state, action: PayloadAction<UserGroup>) {
            state.groups = [...state.groups, action.payload];
        },

        updateGroup(state, action: PayloadAction<{ group: UserGroup }>) {
            const updatedGroups = state.groups.map((group) => {
                if (group.id === action.payload.group.id) {
                    return action.payload.group;
                }

                return group;
            });

            state.groups = updatedGroups;
            state.selectedGroup = state.selectedGroup
                ? find((it: UserGroup) => it.id === (state.selectedGroup ? state.selectedGroup.id : ''), updatedGroups)
                : null;
        },

        updateUser(state, action: PayloadAction<User>) {
            state.selectedUser = action.payload;
            state.users = state.users.map((user) => {
                if (user.id === action.payload.id) {
                    return action.payload;
                } else {
                    return user;
                }
            });
        },

        userCreated(state, action: PayloadAction<string>) {
            state.lastCreatedUserId = action.payload;
        },
    },
});

export const { sessionWarningDialogClosed } = appSlice.actions;

export type AppActionTypes = SliceActions<typeof appSlice.actions>;

const getSessionExpiredAcknowledged = (state: RootState) => state.app.sessionWarningAcknowledged;

export const useSessionExpiredAcknowledged = () => useAppSelector(getSessionExpiredAcknowledged);

export default appSlice;
