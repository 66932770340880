const TABLE_SETTINGS_KEY = 'useradmin.table.settings';

const readSettingsFromStorage = (window: Window) => {
    const settings = window.localStorage.getItem(TABLE_SETTINGS_KEY);
    if (!settings) {
        return {};
    }

    return JSON.parse(settings);
};

const writeSettingsToStorage = (window: Window, settings: Record<string, any>) =>
    window.localStorage.setItem(TABLE_SETTINGS_KEY, JSON.stringify(settings));

const setTableMode = (window: Window) => (tableName: string, mode: string) => {
    const previousSettings = readSettingsFromStorage(window);

    const newSettings = {
        ...previousSettings,
        [tableName]: { mode },
    };

    writeSettingsToStorage(window, newSettings);
};

const getTableMode = (window: Window) => (tableName: string) => {
    const settings = readSettingsFromStorage(window);
    if (settings && settings[tableName] && settings[tableName].mode) {
        return settings[tableName].mode;
    }
    return null;
};

export const configureRepository = (window: Window) => ({
    getTableMode: getTableMode(window),
    setTableMode: setTableMode(window),
});
