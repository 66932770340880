import { combineReducers, configureStore } from '@reduxjs/toolkit';
import settingsReducer from '../../features/setting/settingsSlice';
import langReducer from '../lang/langSlice';
import loginReducer from '../login/loginSlice';
import roleDescriptionReducer from '../../features/app/infoModal/content/concepts/roledescription/roleDescriptionSlice';
import tokenHandlingReducer from '../tokenHandling/tokenHandlingSlice';
import appSlice from '../../features/app/appSlice';
import userSidebarReducerSlice from '../../features/app/users/usersidebar/userSidebarSlice';
import createGroupSlice from '../../features/app/groups/creategroup/createGroupSlice';
import inviteUserSlice from '../../features/app/users/invite/inviteUserSlice';
import groupSidebarSlice from '../../features/app/groups/groupsidebar/groupSidebarSlice';
import notificationSlice from '../../features/notifications/notificationSlice';

export const rootReducer = combineReducers({
    app: appSlice.reducer,
    lang: langReducer,
    login: loginReducer,
    notification: notificationSlice.reducer,
    roleDescription: roleDescriptionReducer,
    setting: settingsReducer,
    tokenHandling: tokenHandlingReducer,
    userSidebar: userSidebarReducerSlice.reducer,
    groupSidebar: groupSidebarSlice.reducer,
    createGroup: createGroupSlice.reducer,
    inviteUser: inviteUserSlice.reducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) =>
    configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
        preloadedState,
    });

export const store = setupStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = AppStore['dispatch'];
