export function dataLayerPush(val: Record<string, any>) {
    if (window.dataLayer) {
        window.dataLayer.push(val);
    }
}

// Naming Conventions for the tracking attributes:
// https://collaboration.msi.audi.com/confluence/display/RIOCC/Google+Analytics%3A+Getting+Started
// Tracking Documentation with a list of currently used categories, actions and labels
// Please add newly added tracking events here
// https://collaboration.msi.audi.com/confluence/display/RIOCC/02.+Tracking+Documentation

export const TRACKING_CATEGORIES = {
    USERADMIN_INFO: 'useradministration, information',
    USERADMIN_USER: 'useradministration, user',
    USERADMIN_USER_GROUP: 'useradministration, userGroup',
};

type TrackingCategory = (typeof TRACKING_CATEGORIES)[keyof typeof TRACKING_CATEGORIES];

export const INFO_TRACKING_ACTIONS = {
    WELCOME_BUTTON_CLICKED: 'welcomeButtonClicked',
    WHAT_IS_NEW_BUTTON_CLICKED: 'whatIsNewButtonClicked',
    FUNCTIONS_BUTTON_CLICKED: 'functionsButtonClicked',
    FUNCTIONS_INVITE_USER_BUTTON_CLICKED: 'functionsInviteUserButtonClicked',
    FUNCTIONS_INVITE_USER_FOR_DRIVER_BUTTON_CLICKED: 'functionsInviteUserForDriverButtonClicked',
    FUNCTIONS_DELETE_USER_BUTTON_CLICKED: 'functionsDeleteUserButtonClicked',
    FUNCTIONS_CREATE_USERGROUP_BUTTON_CLICKED: 'functionsCreateUserGroupButtonClicked',
    FUNCTIONS_DELETE_USERGROUP_BUTTON_CLICKED: 'functionsDeleteUserGroupButtonClicked',
    FUNCTIONS_RESTRICT_USERGROUP_BUTTON_CLICKED: 'functionsRestrictUserGroupButtonClicked',
    ROLE_EXPLANATION_BUTTON_CLICKED: 'roleExplanationButtonClicked',
    GROUPS_EXPLANATION_BUTTON_CLICKED: 'groupsExplanationButtonClicked',
    INFO_BUTTON_CLICKED: 'infoButtonClicked',
    USER_GROUP_DESCRIPTION_LINK_CLICKED: 'userGroupDescriptionLinkClicked',
    USER_GROUP_ROLE_DESCRIPTION_LINK_CLICKED: 'userGroupRoleDescriptionLinkClicked',
    USER_GROUP_TABLE_HEADING_CLICKED: 'userGroupTableHeadingClicked', //
    USER_GROUP_ROLE_TABLE_HEADING_CLICKED: 'userGroupRoleTableHeadingClicked', //
    FLEET_GROUP_ROLE_DESCRIPTION_LINK_CLICKED: 'fleetGroupRoleDescriptionLinkClicked',
    ACCOUNT_SETTINGS_DRIVER_SELF_REGISTRATION_BUTTON_CLICKED: 'driverSelfRegistrationButtonClicked',
} as const;

export type InfoTrackingAction = (typeof INFO_TRACKING_ACTIONS)[keyof typeof INFO_TRACKING_ACTIONS];

export const USER_TRACKING_ACTIONS = {
    CREATE_USER: 'createUser',
    SELECT_USER: 'selectUser',
    DELETE_USER: 'deleteUser',
    SAVE_UPDATED_USER: 'saveUpdatedUser',
    SAVE_CREATED_USER: 'saveCreatedUser',
    DELETE_USER_CONFIRM: 'deleteUserConfirm',
    CONTINUE_USER_EDITING: 'continueUserEditing',
    DISCARD_USER_EDITING: 'discardUserEditing',
} as const;

type UserTrackingAction = (typeof USER_TRACKING_ACTIONS)[keyof typeof USER_TRACKING_ACTIONS];

export const USER_GROUP_TRACKING_ACTIONS = {
    CREATE_USER_GROUP: 'createUserGroup',
    SELECT_USER_GROUP: 'selectUserGroup',
    DELETE_USER_GROUP: 'deleteUserGroup',
    SAVE_UPDATED_USER_GROUP: 'saveUpdatedUserGroup',
    SAVE_CREATED_USER_GROUP: 'saveCreatedUserGroup',
    EDIT_USER_GROUP_DETAILS: 'editUserGroupDetails',
    DELETE_USER_GROUP_CONFIRM: 'deleteUserGroupConfirm',
    CONTINUE_USER_GROUP_EDITING: 'continueUserGroupEditing',
    DISCARD_USER_GROUP_EDITING: 'discardUserGroupEditing',
} as const;

type UserGroupTrackingAction = (typeof USER_GROUP_TRACKING_ACTIONS)[keyof typeof USER_GROUP_TRACKING_ACTIONS];

type TrackingAction = InfoTrackingAction | UserTrackingAction | UserGroupTrackingAction;

export const TRACKING_LABELS = {};

export const executeAndTrack = (func: (evt: any) => void, val: Record<string, any>) => (event: any) => {
    func(event);
    dataLayerPush(val);
};

export function trackingAttributes(category: TrackingCategory, action: TrackingAction, label?: string, value?: any) {
    const attributes: Record<string, any> = {
        'data-track-ga-event-trigger': 'click',
        'data-track-ga-event-category': category,
        'data-track-ga-event-action': action,
    };

    if (label) {
        attributes['data-track-ga-event-label'] = label;
    }

    if (value) {
        attributes['data-track-ga-event-value'] = value;
    }

    return attributes;
}

export function fireTrackingEvent(category: TrackingCategory, action: TrackingAction, label?: string, value?: any) {
    const dataLayerObject: Record<string, any> = {
        event: 'ga_event',
        eventCategory: category,
        eventAction: action,
    };

    if (label) {
        dataLayerObject['eventLabel'] = label;
    }

    if (value) {
        dataLayerObject['eventValue'] = value;
    }

    dataLayerPush(dataLayerObject);
}

export function trackingVisibilityAttributes(
    category: TrackingCategory,
    action: TrackingAction,
    label?: string,
    value?: any
) {
    const attributes: Record<string, any> = {
        'data-track-ga-event-trigger': 'visibility',
        'data-track-ga-event-category': category,
        'data-track-ga-event-action': action,
    };

    if (label) {
        attributes['data-track-ga-event-label'] = label;
    }

    if (value) {
        attributes['data-track-ga-event-value'] = value;
    }

    return attributes;
}
