const MOBILE_SCREEN_BREAKPOINT = '768px';

export const SIDEBAR_BREAKPOINT_FLY = 1440;

export const configureIsMobile = (window: Window) => () => {
    if (window.matchMedia) {
        return window.matchMedia(`(max-width: ${MOBILE_SCREEN_BREAKPOINT})`).matches;
    }
    return false;
};
