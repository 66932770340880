import { CreateGroupState, RestrictionChange } from './createGroupTypes';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SliceActions } from '../../reduxHelper';

export const initialState: CreateGroupState = {
    showCreateGroupDialog: false,
    rolesToAdd: [],
    restrictionsToAdd: [],
    newGroupName: '',
    newGroupDescription: '',
    groupCreationInProgress: false,
};

const compareRelevantFields = (restriction: RestrictionChange, restrictionToBeRemoved: RestrictionChange) =>
    restriction.id !== restrictionToBeRemoved.id || restriction.parentId !== restrictionToBeRemoved.parentId;

const createGroupSlice = createSlice({
    name: 'userSidebarSlice',
    initialState,
    reducers: {
        addRole(state, action: PayloadAction<string>) {
            state.rolesToAdd = [...state.rolesToAdd, action.payload];
        },
        removeRole(state, action: PayloadAction<string>) {
            state.rolesToAdd = state.rolesToAdd.filter((roleId) => roleId !== action.payload);
            state.restrictionsToAdd = state.restrictionsToAdd.filter(
                (restriction) => restriction.parentId !== action.payload
            );
        },
        addRestriction(state, action: PayloadAction<RestrictionChange>) {
            state.restrictionsToAdd = [...state.restrictionsToAdd, action.payload];
        },
        removeRestriction(state, action: PayloadAction<RestrictionChange>) {
            state.restrictionsToAdd = state.restrictionsToAdd.filter((restriction) =>
                compareRelevantFields(restriction, action.payload)
            );
        },
        createGroupDescription(state, action: PayloadAction<string>) {
            state.newGroupDescription = action.payload;
        },
        createGroupName(state, action: PayloadAction<string>) {
            state.newGroupName = action.payload;
        },
        setGroupCreationInProgress(state, action: PayloadAction<boolean>) {
            state.groupCreationInProgress = action.payload;
        },
        resetCreateGroupState() {
            return initialState;
        },
    },
});

export type CreateGroupActionTypes = SliceActions<typeof createGroupSlice.actions>;
export default createGroupSlice;
